/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifCarousel.js                                  !
  !  Desc. : SissiF Front Dialog Paintings Carousel             !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 14/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {Close} from "@mui/icons-material";
import {Box, Dialog, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";

/*
--- Ouestadam products
*/
import {sissifDefDevice, sissifDefModal_e, sissifDefOrientation, sissifDefPaintType} from "./sissifDef";
import {sissifMainRefreshPage_f} from "./sissifMain";
import {OeCarousel} from "./oecommon/oeCarousel";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- If full screen: Set normal screen
    */
    if (paramCtx.fullScreen) {
        /*
        --- Exit from full screen
        */
        paramCtx.fullScreen = false;
        document.exitFullscreen();
    }
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = sissifDefModal_e.noModal;
    /*
    --- Refresh the main page
    */
    sissifMainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locBuildData_f                                 !
  ! Description: Build Carousel Data according the paint type   !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Paintings type                                       !
  ! OUT: - Data                                                 !
  +-------------------------------------------------------------+
*/
function locBuildData_f(paramCtx, paramType) {
    /*
    --- Initialisation
    */
    const locData = [];
    const locMonthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const locMonthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    /*
    --- Scan the Paintings list by years
    */
    let locYear_a = Object.keys(paramCtx.paintList);
    locYear_a.sort();
    let locYear;
    let locMonth_a;
    let locMonth;
    let locPaintName;
    for (let locI = 0; locI < locYear_a.length; locI++) {
        /*
        --- Scan each month
        */
        locYear = locYear_a[locI];
        locMonth_a = Object.keys(paramCtx.paintList[locYear]);
        locMonth_a.sort();
        for (let locJ = 0; locJ < locMonth_a.length; locJ++) {
            /*
            --- Scan each Painting
            */
            locMonth = locMonth_a[locJ];
            for (locPaintName in paramCtx.paintList[locYear][locMonth]) {
                /*
                --- Keep only the painting with same type
                */
                const locPaint = paramCtx.paintList[locYear][locMonth][locPaintName];
                if (paramType === parseInt("" + locPaint.type)) {
                    /*
                    --- Build the Image URL
                    */
                    const locUrl = paramCtx.config_o.urlPaintings + "/" + locYear + "/" + locMonth + "/" + locPaintName + ".jpg";
                    /*
                    --- Build the Caption according the locale
                    */
                    let locCaption;
                    if (paramCtx.config_o.locale === "fr-FR") {
                        locCaption = locMonthFr[parseInt(locMonth) - 1] + " " + locYear +
                            ((locPaint.descriptionFrench.length > 0) ? " : " + locPaint.descriptionFrench : "") +
                            ((locPaint.width > 0) ? " (" + locPaint.width + "cm x " + locPaint.height + "cm)" : "");
                    } else {
                        locCaption = locMonthEn[parseInt(locMonth) - 1] + " " + locYear +
                            ((locPaint.descriptionEnglish.length > 0) ? " : " + locPaint.descriptionEnglish : "") +
                            ((locPaint.width > 0) ? " (" + locPaint.width + "cm x " + locPaint.height + "cm)" : "");
                    }
                    /*
                    --- Add it to data
                    */
                    locData.push({
                        image: locUrl,
                        caption: locCaption
                    });
                }
            }
        }
    }
    /*
    --- Add numbers on each image
    */
    const locNbImages = locData.length;
    for (let locI = 0; locI < locNbImages; locI++) {
        locData[locI].caption = (locI + 1) + "/" + locNbImages + " - " + locData[locI].caption;
    }
    /*
    --- Return the built data
    */
    return locData;
}

/*=============== Local JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : LocCarouselDesktopJSX                             !
! Description: JSX Paintings Carousel for Desktop             !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocCarouselDesktopJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locType = paramProps.type;
    const locColors = locCtx.config_o.colors;

    /*
    --- Build Data record according the type
    */
    const locData = locBuildData_f(locCtx, locType);
    /*
    --- Build the Dialog title
    */
    let locTitle;
    switch (locType) {
        case sissifDefPaintType.large :
            locTitle = locTrans.oeComTransGet_m("mainLarge", "title");
            break
        case sissifDefPaintType.medium:
            locTitle = locTrans.oeComTransGet_m("mainMedium", "title");
            break
        case sissifDefPaintType.small:
            locTitle = locTrans.oeComTransGet_m("mainSmall", "title");
            break
        case sissifDefPaintType.set:
            locTitle = locTrans.oeComTransGet_m("mainSet", "title");
            break
        case sissifDefPaintType.pastel:
            locTitle = locTrans.oeComTransGet_m("mainPastel", "title");
            break
        default:
            locTitle = "";
    }

    /*
    --- Enter in FullScreen if it's not already the case
    */
    if (!locCtx.fullScreen) {
        /*
        --- Set the Full Screen
        */
        locCtx.fullScreen = true;
        document.body.requestFullscreen();
        /*
        --- Set detection of Full Screen Exit
        */
        document.addEventListener('fullscreenchange', (paramEvent) => {
            if (!document.fullscreenElement) {
                /*
                --- Exit of full screen: Close the Dialog
                */
                locCtx.fullScreen = false;
                locClose_f(locCtx, paramEvent);
            }
        });
    }
    /*
    --- Compute the dialog size
    */
    const locWidth = locCtx.window_o.width - 120;
    const locHeight = locCtx.window_o.height - 140;
    const locThumbnails = (locCtx.window_o.height > 700);
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} fullScreen>
                <Box
                    width="100%"
                    height="100%"
                    display="block"
                    alignItems="center"
                    style={{
                        background: locColors.backgroundMainBlack,
                        color: locColors.foregroundMain
                    }}>
                    <DialogTitle sx={{pb: 0}}>
                        <Box sx={{ml: 4, mt: 0, textAlign: "center"}}>
                            {locTitle}
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                            sx={{position: 'absolute', right: 8, top: 8, color: locColors.foregroundMain}}
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{pb: 0, mb: 0, textAlign: "center", minWidth: locWidth + "px"}}>
                        <OeCarousel
                            data={locData}
                            thumbnails={locThumbnails}
                            thumbnailHeight="40px"
                            slideBackgroundColor={locColors.backgroundCarousel}
                            slideImageFit="contain"
                            radius="10px"
                            width={locWidth}
                            height={locHeight}
                            roundRobin={false}
                            showNavBtn={true}
                            fadeNavBtn={false}
                            style={{display: "inline-block"}}
                            captionPosition="bottom-outside"
                            rotate={false}
                        />
                    </DialogContent>
                </Box>
            </Dialog>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine : LocCarouselMobileJSX                              !
! Description: JSX Paintings Carousel for Mobile              !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocCarouselMobileJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locType = paramProps.type;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build Data record according the type
    */
    const locData = locBuildData_f(locCtx, locType);
    /*
    --- Compute the dialog size
    */
    const locWidth = locCtx.window_o.width - 60;
    const locHeight = locCtx.window_o.height - 30;
    const locPortrait = (locCtx.window_o.orientation === sissifDefOrientation.portrait);
    /*
    --- Build the Dialog title only if on portrait mode
    */
    let LocBoxTitle = (<div></div>);
    if (locPortrait) {
        let locTitle;
        switch (locType) {
            case sissifDefPaintType.large :
                locTitle = locTrans.oeComTransGet_m("mainLarge", "title");
                break
            case sissifDefPaintType.medium:
                locTitle = locTrans.oeComTransGet_m("mainMedium", "title");
                break
            case sissifDefPaintType.small:
                locTitle = locTrans.oeComTransGet_m("mainSmall", "title");
                break
            case sissifDefPaintType.set:
                locTitle = locTrans.oeComTransGet_m("mainSet", "title");
                break
            case sissifDefPaintType.pastel:
                locTitle = locTrans.oeComTransGet_m("mainPastel", "title");
                break
            default:
                locTitle = "";
        }
        LocBoxTitle = (
            <Box sx={{ml: 0, mt: 0, textAlign: "center"}}>
                {locTitle}
            </Box>
        );
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} fullScreen>
                <Box
                    width="100%"
                    height="100%"
                    display="block"
                    alignItems="center"
                    sx={{
                        background: locColors.backgroundMainBlack,
                        color: locColors.foregroundMain,
                    }}>
                    <DialogTitle sx={{pb: 0, display: "block"}}>
                        {LocBoxTitle}
                        <IconButton
                            aria-label="close"
                            onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                            sx={{
                                position: 'absolute',
                                right: "15px",
                                top: "12px",
                                zIndex: 10,
                                color: locColors.foregroundMain
                            }}
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{pb: 0, mb: 0, textAlign: "center"}}>
                        <Grid container direction="column" spacing={1} justifycontents="center" alignItems="center">
                            <Grid item>
                                <OeCarousel
                                    data={locData}
                                    thumbnails={false}
                                    thumbnailHeight="40px"
                                    slideBackgroundColor={locColors.backgroundCarousel}
                                    slideImageFit="contain"
                                    radius="10px"
                                    width={locWidth}
                                    height={locHeight}
                                    roundRobin={false}
                                    showNavBtn={true}
                                    fadeNavBtn={true}
                                    style={{display: "inline-block"}}
                                    captionPosition={(locCtx.window_o.orientation === sissifDefOrientation.portrait) ? "bottom-outside-mobile" : "none"}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>
        </div>
    )
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : SissifCarouselJSX                                 !
! Description: JSX Paintings Carousel displaying              !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function SissifCarouselJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locType = paramProps.type;
    /*
    --- Check if it's for Mobile
    */
    if (locCtx.window_o.device === sissifDefDevice.mobile) {
        return (<LocCarouselMobileJSX ctx={locCtx} type={locType}/>);
    } else {
        return (<LocCarouselDesktopJSX ctx={locCtx} type={locType}/>);
    }
}
