/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifMain.js                                      !
  !  Desc. : SissiF Front Locale switcher                       !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 09/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

/*
--- Ouestadam products
*/
import {sissifMainRefreshPage_f} from "./sissifMain";

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : SissifLocaleSwitcherJSX                         !
  ! Description: JSX Locale switcher                            !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function SissifLocaleSwitcherJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return the Switcher
    */
    return (
        <ToggleButtonGroup
            orientation="horizontal"
            value={locCtx.config_o.locale}
            exclusive
            size="small"
            style={{backgroundColor: locColors.foregroundHeader, color: locColors.backgroundMainBlack}}
            onChange={(paramEvent, paramLocale) => {
                if (paramLocale) {
                    locCtx.config_o.locale = paramLocale;
                    locCtx.trans_o.oeComTransChangeLocale_m(paramLocale);
                    sissifMainRefreshPage_f(locCtx);
                }
            }}
        >
            <ToggleButton value="fr-FR" aria-label="fr-FR">
                FR
            </ToggleButton>
            <ToggleButton value="en-GB" aria-label="en-GB">
                EN
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
